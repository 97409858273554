<template>
  <div class="siderBar">
    <div class="side_warp">
      <a-menu
        theme="light"
        mode="inline"
        class="menu"
        :selectedKeys="[$route.meta.menuName]"
      >
       <template v-for="item in routes">
          <a-menu-item
            :key="item.routeLink"
            v-if="!item.childList && !item.hidden"
          >
            <router-link :to="item.routeLink">
              <a-icon :type="item.icon" />
              <span>{{ item.name }}</span>
            </router-link>
          </a-menu-item>
          <a-sub-menu :key="item.routeLink" v-else-if="!item.hidden">
            <span slot="title">
                <a-icon :type="item.icon" />
                <span>{{ item.name }}</span>
            </span>
            <template v-for="subItem in item.childList">

              <a-menu-item :key="subItem.routeLink" v-if="!subItem.hidden && (!subItem.hasOwnProperty('childList') || (subItem.hasOwnProperty('childList')) && !isValidChildMenu(subItem.childList)) ">
                <router-link :to="subItem.routeLink">
                  <!-- <a-icon :type="subItem.icon" /> -->
                  <span>{{ subItem.name }}</span>
                </router-link>
              </a-menu-item>
                <a-sub-menu :key="subItem.routeLink" v-else-if="!subItem.hidden && subItem.hasOwnProperty('childList') && isValidChildMenu(subItem.childList)">
                    <span slot="title">
<!--                        <a-icon :type="item.icon" />-->
                        <span>{{ subItem.name }}</span>
                    </span>
                    <template v-for="(subItemThird) in subItem.childList">
                            <a-menu-item :key="subItemThird.routeLink" v-if="!subItemThird.hidden && !subItemThird.hasOwnProperty('childList')">
                                <router-link :to="subItemThird.routeLink">
                                    <!-- <a-icon :type="subItem.icon" /> -->
                                    <span>{{ subItemThird.name }}</span>
                                </router-link>
                            </a-menu-item>
                    </template>
                </a-sub-menu>
            </template>
          </a-sub-menu>
        </template>
        <!-- <template v-for="item in routes">
          <a-menu-item
            :key="item.routeLink"
            v-if="!item.childList"
            :disabled="item.hidden"
          >
            <router-link :to="item.routeLink">
              <a-icon :type="item.icon" />
              <span>{{ item.name }}</span>
            </router-link>
          </a-menu-item>
          <a-sub-menu :key="item.routeLink" v-else :disabled="item.hidden">
            <span slot="title"
              ><a-icon type="mail" /><span>{{ item.name }}</span></span
            >
            <template v-for="subItem in item.childList">
              <a-menu-item :key="subItem.routeLink" :disabled="subItem.hidden">
                <router-link :to="subItem.routeLink">
                  <a-icon :type="subItem.icon" />
                  <span>{{ subItem.name }}</span>
                </router-link>
              </a-menu-item>
            </template>
          </a-sub-menu>
        </template> -->
      </a-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "siderBar",
  components: {},
  data() {
    return {
      collapsed: false,
      menuList: [
        {
          name: "日常管理",
          routeLink: "/content",
          id: "content",
          icon: "file-word",
          menuId: "1526397344111161346",
          hidden: true,
          childList: [
            {
              name: "用户信息管理",
              routeLink: "/users",
              id: "users",
              menuId: "1526397874111873026",
              hidden: true,
            },
            {
              name: "腕表登记管理",
              routeLink: "/watchRegister",
              id: "watchRegister",
              menuId: "1526397961760243713",
              hidden: true,
            }
          ],
        },
        {
          name: "系统管理",
          routeLink: "/banner",
          id: "banner",
          icon: "gateway",
          menuId: "1526397723351810050",
          hidden: true,
          childList: [
            {
              name: "腕表主数据管理",
              routeLink: "/watchMainData",
              id: "watchMainData",
              menuId: "1526398099614433282",
              hidden: true,
            },
            {
              name: "轮播图管理",
              routeLink: "/bannerLevel",
              id: "bannerLevel",
              menuId: "1526398161123901442",
              hidden: true,
            },
            {
              name: "账号管理",
              routeLink: "/admin",
              id: "admin",
              menuId: "1526398209949794306",
              hidden: true,
            },
            {
              name: "修改密码",
              routeLink: "/password",
              id: "password",
              menuId: "1526398269727014913",
              hidden: true,
            }
          ],
        },
      ],
    };
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    isValidChildMenu(routers){
        if(routers &&  Array.isArray(routers) && routers.length > 0){

            var index = routers.findIndex(item => {
                return Object.prototype.hasOwnProperty.call(item,'menuId');
            })
            return index != -1;
        }

        return false;

    }  ,
    compareRouter(routers,paths){
        var hasOwnVisibleMenu = false;
        if(routers && paths && routers.length > 0&& paths.length > 0) {
            routers.forEach(item => {
                if(paths.findIndex(pItem =>{
                    return pItem.menuId == item.menuId
                })!=-1){
                    hasOwnVisibleMenu = true
                    item.hidden = false;
                }
                if(Object.prototype.hasOwnProperty.call(item, 'childList') &&  Array.isArray(item.childList) && this.isValidChildMenu(item.childList)){
                    var isHas = this.compareRouter(item.childList,paths)
                    if(!isHas){
                        item.hidden = true;
                    }else{
                        item.hidden = false;
                    }
                }

            })
        }

        return hasOwnVisibleMenu
    }
  },
  computed: {
    routes() {
      var routes = this.menuList;
      var pathsList = JSON.parse(localStorage.getItem("menuPathList"));
      this.compareRouter(routes,pathsList)













      // for (var i = 0; i < routes.length; i++) {
      //   //一级菜单是否显示
      //   pathsList.forEach(item => {
      //     if (item.menuId == routes[i].menuId) {
      //       routes[i].hidden = false;
      //     }
      //   });
      //   //二级菜单是否显示
      //   if (routes[i].childList && routes[i].childList.length) {
      //     var indexItem = 0;
      //     for (var k = 0; k < routes[i].childList.length; k++) {
      //       routes[i].childList[k].hidden = true;
      //       for (var j = 0; j < pathsList.length; j++) {
      //         if (routes[i].childList[k].menuId == pathsList[j].menuId) {
      //           routes[i].childList[k].hidden = false;
      //           indexItem++;
      //           // hasChild = true;
      //         }
      //       }
      //     }
      //     // 所有二级菜单不显示则相应的一级菜单不显示
      //     if (indexItem == 0) {
      //       routes[i].hidden = true;
      //     }
      //      //二级菜单有显示则相应的一级菜单显示
      //     else {
      //       routes[i].hidden = false;
      //     }
      //   }
      // }

      // console.log(routes)
      return routes;
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
//.siderBar {
//  height: 100%;
//  background: #ffffff;
//  width: 256px;
//  .sider {
//    height: 100%;
//    .side_warp {
//      height: 100%;
//      display: flex;
//      flex-direction: column;
//      .menu {
//        flex: 1;
//      }
//    }
//  }
//  .siderFooter {
//    height: 40px;
//    line-height: 40px;
//    width: 100%;
//    padding: 0 16px;
//  }
//}
</style>
