<template>
  <div class="header">
    <div class="logo">
      <!-- <img src="../assets/image/logo.svg" alt="" /> -->
      <h1>ZENITH WARRANTY MP CMS</h1>
    </div>
    <div class="content"></div>
    <div class="header_right">
      <div class="help" @click="toHelp">
        <a-tooltip class="header-item" title="帮助文档" placement="bottom">
          <a-icon type="question-circle" />
        </a-tooltip>
      </div>
      <a-dropdown class="lang header-item">
        <div>
          <a-avatar
            src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
            size="small"
            class="avatar"
          />
          {{ nickName }}
        </div>
        <a-menu slot="overlay">
          <template v-for="item in langList">
            <a-menu-item :key="item.key" @click="selectHeadMenus(item)"
              ><a-icon :type="item.icon" />{{ item.name }}</a-menu-item
            >
          </template>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminHeader",
  components: {},
  data() {
    return {
      nickName: "",
      langList: [
        {
          key: 1,
          name: "修改密码",
          icon: "setting"
        },
        {
          key: 2,
          name: "退出登录",
          icon: "logout"
        }
      ]
    };
  },
  methods: {
    selectHeadMenus(item) {
      if (item.key === 1) {
        this.$router.push("/password");
      } else {
        this.$router.push("/user/login");
        localStorage.clear();
      }
    },
    toHelp() {
      this.$router.push("/help");
    }
  },
  computed: {},
  created() {
    this.nickName = localStorage.getItem("nickName");
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.header {
  padding: 0 16px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;
    //height: 48px;
    height: 100%;

    img {
      height: 28px;
    }
    h1 {
      color: #ffffff;
      height: 32px;
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 0;
      margin-left: 12px;
    }
  }

  .content {
    flex: 1;
  }
  .header_right {
    display: flex;
    color: #ffffff;
    .header-item {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 12px;
      cursor: pointer;
      transition: all 0.3s;
    }
    .avatar {
      margin-right: 8px;
    }
  }
}
</style>
