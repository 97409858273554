<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-header id="header">
      <admin-header></admin-header>
    </a-layout-header>
    <a-layout>
      <a-layout-sider theme="light" v-model="collapsed" collapsible>
        <!--        <div class="logo" />-->
        <sider-bar></sider-bar>
        <span slot="trigger">
          <a-icon type="menu-unfold" v-if="collapsed" />
          <a-icon type="menu-fold" v-else />
        </span>
      </a-layout-sider>
      <a-layout-content class="content_warp">
        <div class="breadcrumb">
          <breadcrumb class="breadcrumb-container" />
        </div>

        <router-view class="main_content"></router-view>
        <a-layout-footer style="textAlign: center">
          <div class="ant-pro-global-footer-links">
            <a title="Sothebys" target="_blank" href="https://www.sothebys.com/"
              >ZENITH</a
            >
          </div>
          <div class="copyright">Copyright ©2020 ZENITH</div>
        </a-layout-footer>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import AdminHeader from "./header";
import SiderBar from "./siderBar";
import Breadcrumb from "./Breadcrumb";
export default {
  components: { AdminHeader, SiderBar, Breadcrumb },
  data() {
    return {
      collapsed: false,
    };
  },
};
</script>
<style scoped>
#components-layout-demo-custom-trigger {
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#header {
  padding: 0px;
  height: 48px;
  line-height: 48px;
  width: 100%;
  z-index: 100;
  right: 0px;
}
.content_warp {
  display: flex;
  flex-direction: column;
}
.main_content {
  flex: 1;
}
a {
  color: rgba(0, 0, 0, 0.45);
}

.copyright {
  color: rgba(0, 0, 0, 0.45);
}
.breadcrumb {
    height: 64px;
    position: relative;
    background: #fff;
    padding: 0 24px;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  }
   .breadcrumb-container {
      float: left;
      height: 64px;
      line-height: 64px;
      color: rgba(0,0,0,.85);
    font-weight: 600;
    font-size: 20px;
    }
</style>
